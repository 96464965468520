.bg {
  background-color: #f0f6ff;
}

.blueBorderButton {
  border: 1px solid #0066f5;
  background: transparent;
  padding: 10px 20px;
  color: #0066f5;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .headingText {
    font-size: 20px !important;
  }

  .top5 {
    padding-top: 15px !important;
  }

  .noMl {
    margin-left: 12px !important;
  }

  .topMargin {
    margin-top: 30px !important;
  }
}
