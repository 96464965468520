.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.defaultP {
  font-size: 12px;
  font-weight: 500;
  color: #898989;
}

.alignCenter {
  align-items: center;
}

.topAbout {
  padding-top: 50px;
  /* margin-bottom: 40px; */
}

.aboutVid img {
  width: 100%;
}

.width70 {
  width: 70%;
}

.width40 {
  width: 40%;
}

.width50 {
  width: 50%;
}

.blueBox {
  width: 12px;
  height: 12px;
  background-color: #2c3093;
  border-radius: 2px;
}

.greenBox {
  width: 12px;
  height: 12px;
  background-color: #076538;
  border-radius: 2px;
}

.orangeBox {
  width: 12px;
  height: 12px;
  background-color: #f45531;
  border-radius: 2px;
}

.lightBlueBox {
  width: 12px;
  height: 12px;
  background-color: #3ca4de;
  border-radius: 2px;
}

.darkOrangeBox {
  width: 12px;
  height: 12px;
  background-color: #e01a37;
  border-radius: 2px;
}

.magBox {
  width: 12px;
  height: 12px;
  background-color: #b33195;
  border-radius: 2px;
}

.lightGreenBox {
  width: 12px;
  height: 12px;
  background-color: #1fc979;
  border-radius: 2px;
}

.headingText {
  font-weight: 600;
  font-size: 28px;
}

.subHeadingText {
  font-size: 16px;
  font-weight: 600;
}

.width30 {
  width: 30%;
}

.mentorHeader {
  color: #000000;
  font-weight: 600;
  font-size: 24px;
  line-height: 50px;
}

/* .slick-track {
  display: flex !important;
} */
@media screen and (max-width: 440px) {
  .width70,
  .width30 {
    width: 100%;
  }

  .width40 {
    width: 100%;
  }

  .width50 {
    width: 100%;
  }

  .buttonPosition {
    flex-direction: column;
  }

  .rightButton {
    margin-left: 0 !important;
    margin-top: 15px;
  }

  .greyButton {
    text-align: center !important;
    justify-content: center;
  }

  .iconContainer {
    padding-bottom: 0 !important;
  }

  .phone {
    width: 100%;
    margin: 50px 0 !important;
  }

  .topAbout > .row,
  .aboutTimeline {
    padding: 0 !important;
  }

  .icon-container {
    padding-bottom: 50px;
  }

  .bg {
    margin-top: 30px;
  }
}
