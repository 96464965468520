@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 10vh;
  background-color: #0066f5 !important;
  z-index: 999;
}
.logo {
  font-size: 30px;
}

.logoImgHolder {
  width: 50px;
}

.logoImgHolder img {
  width: 100%;
}

.nav-links {
  display: flex;
  /* justify-content: flex-end;
  width: 75%; */
}

.nav-links li {
  color: #fff;
  font-size: 14px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.storeWrapper {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 15px;
  border-radius: 50px;
}

.downloadFrom {
  font-size: 8px;
}

.stores {
  font-size: 10px;
  font-weight: bold;
}
.hero {
  text-align: center;
  margin-top: 15%;
}
.hero h1 {
  text-align: center;
  font-size: 80px;
}
.mobile-menu-icon {
  display: none;
}
button {
  border: none;
  text-decoration: none;
  font-size: 20px;
  background: none;
}

.faq,
.faq:hover {
  color: #fff;
}

@media (max-width: 500px) {
  .logoImgHolder {
    margin-left: 10px !important;
  }
}
@media (max-width: 768px) {
  .logo {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: #0066f5 !important;
    height: 99vh;
  }

  .buttonPositionn {
    justify-content: flex-start !important;
  }
  ul li {
    padding: 24px 32px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: left;
    color: #fff !important;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
  }
  .hero h1 {
    font-size: 50px;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
  }
}
