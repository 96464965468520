.downloadHeader {
  color: #0066f5;
  font-weight: 700;
  font-size: 38px;
  line-height: 50px;
}

/* .phone {
  width: 500px;
} */

.phone img {
  width: 100%;
}

.greyButton {
  border: none;
  background: #222222;
  padding: 10px 25px;
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 500;
}

.store {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .downloadHeader {
    font-size: 30px !important;
  }

  .pTopMobile {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
