.skewed-backgrounds {
  position: relative;
  top: 0;
  overflow: hidden;
  height: 100vh;
  background-color: #0066f5;
  padding-top: 100px;
  transform: skewY(6deg);
  transform-origin: bottom right;
  z-index: 1;
}

.skewed-backgrounds-content {
  padding-top: 40px;
  color: aliceblue;
  position: relative;
  transform: skewY(-6deg);
  z-index: 2;
}

.headerTexts {
  margin-top: 20px;
  font-weight: 600;
  line-height: 50px;
  /* letter-spacing: 1px; */
  font-size: 40px;
}

.skewed-backgrounds-content p {
  /* width: 30%; */
  font-size: 14px;
}

.defaultButton {
  border: 1px solid #fff;
  background: #9dc4fb78 !important;
  padding: 10px 20px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
}

.bannerParagraph {
  text-align: left;
  width: 100%;
}

.em {
  display: flex;
}

.emailField {
  text-align: left;
  padding-left: 20px;
  width: 350px;
  height: 60px;
  background: #fff;
  border-radius: 20px;
}

.emailField2 {
  display: none;
}

.buttonField {
  width: 150px;
  height: 60px;
  background: #2a7ff6;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
}
.buttonField:focus {
  outline: 0 !important;
  border: none !important;
}

.modalButton {
  background: #2a7ff6;
  padding: 15px 0;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
}
.learnMoreButtonField {
  width: 150px;
  height: 50px;
  background: #2a7ff6;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
}

.learnMoreButtonField:focus,
.modalButton:focus {
  outline: 0 !important;
  border: none !important;
}

.noStyle {
  border: none !important;
  outline: 0 !important;
  width: 90% !important;
  height: 40% !important;
  color: #000;
  font-weight: 500;
  margin-top: 13px;
  font-size: 13px;
}
.noStyle::placeholder {
  color: #898989;
  font-weight: 500;
  font-size: 13px;
  opacity: 1; /* Firefox */
}

.noStyle::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #898989;
  font-weight: 500;
  font-size: 13px;
}

.videoStyles {
  width: 100%;
  height: 300px;
}

.scholarsHeader {
  color: #000;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
}

.defaultP2 {
  font-size: 14px;
  font-weight: 500;
  color: #898989;
  line-height: 25px;
}

.defaultPWhite {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 25px;
}

.scholarWrapper {
  width: 50%;
}

@media screen and (max-width: 380px) {
  .scholarWrapper {
    width: 90% !important;
  }
  .skewed-backgrounds {
    height: 95vh !important;
    padding-top: 30px;
  }
  .scholarsHeader {
    font-weight: 700;
    line-height: 30px;
  }

  .emailField2 {
    display: flex;
  }
}

@media screen and (max-width: 400px) {
  .scholarWrapper {
    width: 90% !important;
  }
  .scholarsHeader {
    font-weight: 700;
    line-height: 30px;
  }
  .skewed-backgrounds {
    height: 100vh !important;
    padding-top: 30px;
  }

  .headerTexts {
    font-weight: bold;
    line-height: 40px !important;
    font-size: 28px;
  }

  .skewed-backgrounds-content {
    padding-top: 0;
  }
  .videoStyles {
    padding: 0px !important;
    /* margin-top: 50px; */
    width: 100%;
    height: 300px;
    margin-top: 30px;
  }

  .bannerParagraph {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .emaill {
    display: none !important;
  }

  .emailField2 {
    display: flex;
  }
}

@media screen and (max-width: 440px) {
  .scholarWrapper {
    width: 90% !important;
  }
  .scholarsHeader {
    font-weight: 700;
    line-height: 30px;
  }
  .headerTexts {
    padding-top: 0px !important;
    /* margin-top: 20px !important; */
    font-weight: bold;
    line-height: 40px !important;
    font-size: 28px;
  }

  .skewed-backgrounds {
    height: 100vh;
    padding-top: 30px;
  }
  .skewed-backgrounds-content {
    padding-top: 0;
  }
  .videoStyles {
    margin-top: 30px;
    width: 100%;
    height: 300px;
  }

  .emailField2 {
    display: flex;
  }

  .emaill {
    display: none !important;
  }
}
