.footerBg {
  background-color: #0066f5;
}

.logoContainer {
  width: 70px;
}

.logoContainer img {
  width: 100%;
}

.footerLink ul {
  padding-left: 0;
}

.footerLink ul li {
  display: inline;
  text-decoration: none;
  list-style: none;
  font-size: 13px;
  color: #000 !important;
}

.defaultP2 {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 768px) {
  .spaceIcons {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    /* justify-content: center; */
  }

  .iconsContainer {
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto;
  }

  .footerDiv {
    display: block !important;
  }

  .noMleft {
    margin-left: 0 !important;
  }

  .logoContainer {
    width: 40px;
    margin-bottom: 20px;
  }
}

/* .footerLink ul li:not(:first-child) {
  margin-left: 20px;
} */
