h1 {
  font-size: 42px;
  margin: 0px;
  margin-bottom: 30px;
}

.accordion-simple > .active {
  display: block;
}

.accordion .inactive {
  display: none;
}

.accordion > div {
  /* background-color: #fff; */
  /* margin-bottom: 20px; */
  padding: 0px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.accordion_title {
  color: blue;
  padding-bottom: 20px;
  font-weight: 500;
}

.accordion-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #000; */
}

.accordion-heading .active {
  color: #333333;
}

.accordion-heading h6 {
  color: #333333;
  font-weight: 500;
}

.bg {
  background-color: #f9f9f9;
  padding: 30px;
}

.answerDiv {
  background-color: #fff;
  padding: 15px;
  font-size: 14px;
}

.accordion .breakline:last-child() {
  display: none;
}

@media (max-width: 768px) {
  .pBottom {
    padding-bottom: 0 !important;
  }
}
