/* .image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 5%;
  margin: 0 auto;
  width: 150px;
  height: auto;
}



/* .image-container {
  width: 150px;
} */

.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 20px; /* Border radius, if desired */
  /* margin: 0 10px !important; */
  width: 250px; /* Fixed width */
  height: 350px; /* Fixed height */
}

.image-container img {
  width: 95%;
  height: 100%;
  border-radius: 10px;
  outline: none; /* Remove the blue focus border */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.image-container:not(:first-child) {
  margin-left: 20px !important;
}

.image-container:focus {
  outline: none;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 95%;
  height: 40%;
  border-radius: 0 0 10% 10%;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay color and transparency */
  color: #fff; /* Overlay text color */
  padding: 20px;
  box-sizing: border-box;
}

.overlay h2 {
  margin-top: 0;
}

.overlay p {
  margin-bottom: 0;
}

.slider-container {
  width: 100%;
}

.slider-container img {
  width: 100%;
  height: auto;
}

.sliderImageName {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.sliderImageTitle {
  font-size: 14px;
  font-weight: 450;
  line-height: 18px;
}

.sliderImageYear {
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
}

.bagImage {
  width: 15px;
}

.bagImage img {
  width: 100%;
}

@media (max-width: 768px) {
  .blockOnMobile {
    display: block !important;
  }

  .noPy {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sliderImageName,
  .sliderImageYear {
    font-size: 16px !important;
  }

  .overlay {
    height: 50% !important;
  }
}

@media (max-width: 390px) {
  .sliderImageName,
  .sliderImageTitle,
  .sliderImageYear {
    font-size: 14px !important;
  }

  .noPb {
    padding-top: 12px !important;
  }

  .overlay {
    height: 33% !important;
    width: 99%;
  }

  .image-container img {
    width: 99%;
    align-items: center;
  }
}
