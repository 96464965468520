.icon-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  border-radius: 50%;
  background-color: #f0f6ff;
  margin: 50px 0;
}

.test .icon:not(:last-child) {
  position: relative;
}

/* .icon:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% + 10px);
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border-style: dotted;
  margin-top: 2px;
} */

.test .icon:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% + 10px);
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0f6ff;
  border: 1px dotted #f0f6ff;
  margin-top: 5px;
}

.icon:not(:last-child)::after {
  background-image: repeating-linear-gradient(
    to bottom,
    #f0f6ff,
    #f0f6ff 3px,
    transparent 3px,
    transparent 6px
  );
}

.icon-text {
  margin-top: 5px;
  text-align: center;
}

.test {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 440px) {
  .topAbout h5 {
    margin-bottom: 10px !important;
  }
}
