.skewed-background {
  position: relative;
  top: 0;
  overflow: hidden;
  height: 90vh;
  background-color: #0066f5; /* Replace with your desired background color */
  padding-top: 100px;
  transform: skewY(6deg); /* Adjust the angle of skew as per your preference */
  transform-origin: bottom right;
  z-index: 1;
}

.skewed-background-content {
  padding-top: 10px;
  color: aliceblue;
  position: relative;
  transform: skewY(-6deg);
  z-index: 2;
}

.bannerImageHolder {
  max-width: 23%;
  height: auto;
  margin: 0 auto;
}

.bannerImageHolder img {
  width: 100%;
  margin-top: 80px;
}

.headerText {
  font-weight: bold;
  line-height: 80px;
  font-size: 60px;
}

.skewed-background-content p {
  width: 30%;
  font-size: 14px;
}

.defaultButton {
  border: 1px solid #fff;
  background: #9DC4FB78 !important;
  padding: 10px 20px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
}

@media screen and (max-width: 440px) {
  .headerText {
    font-weight: bold;
    line-height: 40px !important;
    font-size: 32px;
  }

  .bannerImageHolder {
    max-width: 80%;
    margin: 0 auto;
  }

  .bannerImageHolder img {
    margin-top: 60px;
  }

  .skewed-background {
    height: 85vh;
  }
  .skewed-background-content {
    padding-top: 0;
  }

  .skewed-background-content p {
    width: 90%;
  }
}
